<template>
    <div class="container">
        <div class="reset-all-styles" v-html="page.content"></div>
    </div>
</template>

<script>
import {get} from '@/lib/axios';

export default {
    name: 'About',
    data: () => ({
        type: 3,
        page: {},
    }),
    methods: {
        async fetchData() {
            await get('static-page/' + this.type).then(response => {
                this.page = response.data.data
            })
        }
    },
    mounted() {
        this.fetchData()
    },
    metaInfo() {
        return {
            title: `О нас - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>

</style>
